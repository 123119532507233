import { Timestamp } from 'firebase/firestore'

/**
 * Convert a Firestore Timestamp to a formatted date string.
 * @param {Timestamp} timestamp - The Firestore Timestamp to convert.
 * @param {string} [format='en-US'] - The locale for date formatting.
 * @returns {string} - Formatted date string.
 */
export const timestampToDateString = (timestamp, format = 'nl-NL') => {
    if (!timestamp || !(timestamp instanceof Timestamp)) {
        throw new Error('Invalid Firestore Timestamp provided.')
    }

    // Convert Firestore Timestamp to JavaScript Date
    const date = timestamp.toDate()

    // Format the date as a string
    // e.g. "10/25/2023, 12:34:56 PM"
    return date.toLocaleDateString(format, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    })
}

export const timestampToTimeString = (timestamp, format = 'nl-NL') => {
    if (!timestamp || !(timestamp instanceof Timestamp)) {
        throw new Error('Invalid Firestore Timestamp provided.')
    }

    // Convert Firestore Timestamp to JavaScript Date
    const date = timestamp.toDate()

    // Format the time as a string (without the date)
    return date.toLocaleTimeString(format, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Don't use 12-hour format (e.g., "12:34 PM")
    })
}

export const dateStringToTimestamp = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
        throw new Error('Invalid date string provided.')
    }

    // Parse the date string into a JavaScript Date object
    const date = new Date(dateString)

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date string format.')
    }

    // Convert the JavaScript Date to a Firestore Timestamp
    // (e.g., Timestamp { seconds: 1698234896, nanoseconds: 0 })
    return Timestamp.fromDate(date)
}
