import { createContext, useState, useEffect, useContext } from 'react'
import { useAuth } from '../util/auth'
import {
    collection,
    query,
    where,
    getDocs,
    getFirestore,
} from 'firebase/firestore'
import { firebaseApp } from '../util/firebase'

const OrganisationContext = createContext()

export const OrganisationProvider = ({ children }) => {
    const auth = useAuth()
    const [memberOrganisations, setMemberOrganisations] = useState([])
    const [selectedOid, setSelectedOid] = useState(null)
    const [selectedOrganisation, setSelectedOrganisation] = useState(null)

    const db = getFirestore(firebaseApp)

    useEffect(() => {
        if (!auth.user) return // Wacht totdat auth.user beschikbaar is

        const fetchOrganisations = async () => {
            const q = query(
                collection(db, 'organisations'),
                where('members', 'array-contains', auth.user.id)
            )
            const querySnapshot = await getDocs(q)
            const organisationsWhereUserIsMember = querySnapshot.docs.map(
                (doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })
            )
            setMemberOrganisations(organisationsWhereUserIsMember)
            const selectedId =
                selectedOid ||
                localStorage.getItem('selectedOid') ||
                auth.user?.oid
            setSelectedOid(selectedId)
            setSelectedOrganisation(
                organisationsWhereUserIsMember.find(
                    (org) => org.id === selectedId
                )
            )
        }

        fetchOrganisations()
    }, [auth.user, db, selectedOid])

    return (
        <OrganisationContext.Provider
            value={{
                selectedOid,
                setSelectedOid,
                selectedOrganisation,
                memberOrganisations,
            }}
        >
            {children}
        </OrganisationContext.Provider>
    )
}

export const useOrganisation = () => useContext(OrganisationContext)
