import FormField from './FormField'
import { useForm } from 'react-hook-form'
import EventPage from '../datasets/event-page.json'
import PageLoader from './PageLoader'
import { useOutletContext } from 'react-router-dom'
import { updateEvent } from '../util/db_events'
import { firebaseTimestamp } from '../util/util'
import { dateStringToTimestamp, timestampToTimeString } from '../util/timestamp'
import { debounce } from 'lodash'
import Alert from './Alert'
import { useCallback, useEffect } from 'react'

const EventForm = () => {
    const { setNotification, event, userObj } = useOutletContext()
    const reactHookForm = useForm({
        defaultValues: { ...event },
        mode: 'all',
    })
    const { handleSubmit, errors, watch } = reactHookForm

    const onSubmit = async (formData) => {
        const updatedEvent = { ...formData }

        try {
            updatedEvent.lastEditedBy = userObj.id
            updatedEvent.lastEditedAt = firebaseTimestamp()

            if (formData.date) {
                updatedEvent.eventStart = dateStringToTimestamp(
                    `${formData.date}T${timestampToTimeString(
                        event.eventStart
                    )}`
                )
            }

            // if (formData.eventEnd)
            //     updatedEvent.eventEnd = convertToFirebaseTimestamp(
            //         `${formData.date} ${formData.eventEnd}`
            //     )
            // if (formData.showStart)
            //     updatedEvent.showStart = convertToFirebaseTimestamp(
            //         `${formData.date} ${formData.showStart}`
            //     )
            // if (formData.showEnd)
            //     updatedEvent.showEnd = convertToFirebaseTimestamp(
            //         `${formData.date} ${formData.showEnd}`
            //     )

            updateEvent(event.id, updatedEvent)
                .then(() => {
                    console.log('Event Updated:', updatedEvent)
                    setNotification({
                        show: true,
                        message: 'Your event is saved.',
                        severity: 'success',
                    })
                    setTimeout(() => {
                        setNotification({ show: false })
                    }, 5000)
                })
                .catch((error) => {
                    setNotification({
                        show: true,
                        message: 'Error: changes cannot be saved',
                        severity: 'error',
                    })
                    console.log(error)
                })
        } catch (e) {
            console.log('Error creating event', e)
        }
    }

    // Debounced versie van saveData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSave = useCallback(
        debounce((data) => {
            onSubmit(data)
        }, 2000),
        []
    )

    useEffect(() => {
        const subscription = watch((data) => debouncedSave(data))
        return () => subscription.unsubscribe() // Cleanup
    }, [watch, debouncedSave])

    return Object.keys(event).length > 0 ? (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="dark:bg-midnight-600 text-silver shadow-sm drop-shadow-md sm:rounded-xl md:col-span-2 mx-4"
        >
            {/* <FormField
                key={`test`}
                form={EventPage['/overview'].sections[0].body[0]}
                reactHookForm={reactHookForm}
            /> */}
            {errors && <Alert severity="warning" message={errors[0]} />}
            <div className="p-4">
                <div className="space-y-10 divide-y divide-silver">
                    {EventPage['/overview']?.sections.map((data) => (
                        <div
                            key={data.key}
                            className="grid grid-cols-1 gap-x-4 md:grid-cols-2"
                        >
                            <div className="px-4 sm:px-4 py-2">
                                <h2 className="text-base/7 font-semibold text-silver-500">
                                    {data.header?.title}
                                </h2>
                                <p className="mt-1 text-sm/6 text-silver-700">
                                    {data.header?.subtitle}
                                </p>
                            </div>

                            <div className="px-4">
                                <div className="max-w-2xl mt-4 gap-x-6 gap-y-4 ">
                                    {data.body.map((field) => (
                                        <FormField
                                            key={`${field.key}-${field.name}`}
                                            form={field}
                                            reactHookForm={reactHookForm}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* <button type="submit">Save</button> */}
        </form>
    ) : (
        <PageLoader />
    )
}

export default EventForm
