'use client'

import {
    Button,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from '@headlessui/react'
import { useState } from 'react'
import FormField from './FormField'
import { useAuth } from '../util/auth'
import { useOrganisation } from '../hooks/useOrganisation'

const DataModal = ({ open, onClose, dataset, reactHookForm, onSubmit }) => {
    const {
        getValues,
        reset,
        handleSubmit,
        formState: { errors },
    } = reactHookForm
    const [step, setStep] = useState(0)
    const auth = useAuth()
    const { selectedOrganisation } = useOrganisation()

    return (
        <div>
            <Dialog open={open} onClose={onClose} className="relative z-10">
                <form
                // onSubmit={(e) => {
                //   handleSubmit(onSubmit);
                //   e.preventDefault();
                //   console.log(e);
                // }}
                >
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-midnight-500/75 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300  data-[enter]:ease-out"
                    />

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                data-dialog="dialog"
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="absolute top-0 right-0 size-6">
                                    <button
                                        onClick={onClose}
                                        type="button"
                                        className="close"
                                        aria-hidden="true"
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div>
                                    <div className="text-left">
                                        {dataset[step].header.title && (
                                            <DialogTitle
                                                as="h3"
                                                className="text-lg font-semibold text-silver-900"
                                            >
                                                {dataset[step].header.title}
                                            </DialogTitle>
                                        )}
                                        {dataset[step].header.subtitle && (
                                            <DialogTitle
                                                as="h5"
                                                className="text-sm font-semibold text-silver-900"
                                            >
                                                {dataset[step].header.subtitle}
                                            </DialogTitle>
                                        )}
                                        <div className="mt-4">
                                            {dataset[step].readOnly ? (
                                                <div>
                                                    <table className="min-w-full divide-y divide-silver-300">
                                                        <tbody>
                                                            {dataset[
                                                                step
                                                            ].body.map(
                                                                (item) => (
                                                                    <tr
                                                                        key={
                                                                            item.key ||
                                                                            item.name
                                                                        }
                                                                    >
                                                                        <td className="whitespace-nowrap pl-4 pr-3 text-sm font-medium text-silver-900 sm:pl-0">
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </td>
                                                                        <td className="capitalize whitespace-nowrap px-3 text-sm text-silver-800">
                                                                            {
                                                                                item.pre
                                                                            }{' '}
                                                                            {item.database
                                                                                ? item.organisation
                                                                                    ? selectedOrganisation[
                                                                                          item
                                                                                              .name
                                                                                      ]
                                                                                    : auth
                                                                                          .user[
                                                                                          item
                                                                                              .name
                                                                                      ]
                                                                                : item.boolean
                                                                                ? getValues(
                                                                                      item.name
                                                                                  )
                                                                                    ? 'yes'
                                                                                    : 'no'
                                                                                : getValues(
                                                                                      item.name
                                                                                  ) ||
                                                                                  'unkown'}{' '}
                                                                            {
                                                                                item.post
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    {Object.keys(errors)
                                                        .length > 0 && (
                                                        <div className="grid text-sm text-red pt-4 ">
                                                            <span className="min-width-full grid-cols-1 sm:grid-cols-1">
                                                                Some input is
                                                                missing or
                                                                invalid.
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                dataset[step].body.map(
                                                    (fields) => (
                                                        <FormField
                                                            key={
                                                                fields.key ||
                                                                fields.name
                                                            }
                                                            form={fields}
                                                            reactHookForm={
                                                                reactHookForm
                                                            }
                                                        />
                                                    )
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {dataset.length > 0 && (
                                    <div className="mt-5 sm:mt-6 flex justify-between">
                                        {step > 0 ? (
                                            <Button
                                                type="button"
                                                onClick={() =>
                                                    setStep(step - 1)
                                                }
                                                className="capitalize py-2 px-4 justify-center rounded-md bg-silver text-sm font-semibold text-white shadow-sm hover:bg-red-300"
                                            >
                                                Back
                                            </Button>
                                        ) : (
                                            <Button
                                                type="button"
                                                onClick={(e) => {
                                                    reset()
                                                    onClose(e)
                                                }}
                                                className="capitalize py-2 px-4 justify-center rounded-md bg-silver text-sm font-semibold text-white shadow-sm hover:bg-red-300"
                                            >
                                                Close
                                            </Button>
                                        )}
                                        <div>
                                            <nav
                                                aria-label="Progress"
                                                className="flex items-center justify-center"
                                            >
                                                <ol className="my-3 flex items-center space-x-3">
                                                    {dataset.map(
                                                        (circle, index) => (
                                                            <li
                                                                key={circle.key}
                                                            >
                                                                {step ===
                                                                index ? (
                                                                    <button
                                                                        aria-current="step"
                                                                        className="relative flex items-center justify-center"
                                                                    >
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="absolute flex size-5 p-px"
                                                                        >
                                                                            <span className="size-full rounded-full bg-blue-100" />
                                                                        </span>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="relative block size-2.5 rounded-full bg-blue-500"
                                                                        />
                                                                        <span className="sr-only">
                                                                            {
                                                                                circle.key
                                                                            }
                                                                        </span>
                                                                    </button>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            onClick={() => {
                                                                                setStep(
                                                                                    index
                                                                                )
                                                                            }}
                                                                            title={
                                                                                circle.key
                                                                            }
                                                                            data-tooltip-target="tooltip-default"
                                                                            className="block size-2.5 rounded-full bg-silver-200 hover:bg-silver-400"
                                                                        >
                                                                            <span className="sr-only">
                                                                                {
                                                                                    circle.key
                                                                                }
                                                                            </span>
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </li>
                                                        )
                                                    )}
                                                </ol>
                                            </nav>
                                        </div>
                                        {step === dataset.length - 1 ? (
                                            <Button
                                                type="button"
                                                className="capitalize py-2 px-4 justify-center rounded-md bg-blue-700 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:bg-silver-600"
                                                disabled={
                                                    Object.keys(errors).length >
                                                    0
                                                }
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Finalize
                                            </Button>
                                        ) : (
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setStep(step + 1)
                                                }}
                                                className="capitalize py-2 px-4 justify-center rounded-md bg-blue-700 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:bg-silver-600"
                                            >
                                                Next
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </DialogPanel>
                        </div>
                    </div>
                </form>
            </Dialog>
        </div>
    )
}

export default DataModal
