import '../styles/calendarIcon.css'

export const CalendarIcon = ({ date }) => {
    var newDate = new Date(date.toDate())
    var month = newDate.toLocaleString('default', { month: 'long' })
    var day = newDate.getDate()

    return (
        <div className="calendar-icon-container">
            <div className="calendar-icon-header uppercase">{month}</div>
            <div className="calendar-icon-body">{day}</div>
        </div>
    )
}
