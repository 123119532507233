// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = ({ name, style, size, className }) => {
    const checkStyle = (stl) => {
        switch (stl) {
            case 'bold':
                return 'br'
            case 'solid':
                return 'sr'
            case 'thin':
                return 'tr'
            default:
                return 'rr'
        }
    }

    return (
        <i
            className={`fi fi-${checkStyle(style)}-${name} ${className}`}
            style={{ fontSize: `${size}pt`, verticalAlign: 'middle' }}
            aria-hidden="true"
        ></i>
    )
    // <FontAwesomeIcon icon={`fa-${name}`} size={size}  />
}

export default Icon
