import React from 'react'
import Meta from './../components/Meta'
import AuthSection from './../components/AuthSection'
import { useParams } from './../util/router'

function AuthPage(props) {
    const params = useParams()

    return (
        <>
            <Meta title="Envio - Sign In" />
            <AuthSection
                size="md"
                bgColor="bg-white dark:bg-darknight"
                bgImage=""
                bgImageOpacity={1}
                textColor="text-black dark:text-silver"
                type={params.type}
                providers={['google', 'facebook', 'twitter']}
                afterAuthPath={params.next || '/dashboard'}
            />
        </>
    )
}

export default AuthPage
