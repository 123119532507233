import React from 'react'
import DashboardSection from './../../components/DashboardSection'
import Meta from './../../components/Meta'

const EventsPage = (props) => {
    return (
        <>
            <Meta title="Events" />
            <DashboardSection
                title="Events"
                subtitle="All events listed"
                strapline=""
                size="lg"
                bgColor="bg-white dark:bg-darknight"
                textColor="text-black dark:text-silver"
                {...props}
            />
        </>
    )
}

export default EventsPage
