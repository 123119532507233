import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import {
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
} from '@heroicons/react/24/outline'

export default function Alert({ severity, message }) {
    const type = (sv) => {
        switch (sv) {
            case 'warning':
                return {
                    color: 'black',
                    icon: (
                        <ExclamationTriangleIcon
                            aria-hidden="true"
                            className="size-5 text-yellow-400"
                        />
                    ),
                }
            case 'error':
                return {
                    color: 'red',
                    icon: (
                        <ExclamationCircleIcon
                            aria-hidden="true"
                            className="size-5 text-red-400"
                        />
                    ),
                }
            case 'success':
                return {
                    color: 'white',
                    icon: (
                        <CheckCircleIcon
                            aria-hidden="true"
                            className="size-5 text-green-400"
                        />
                    ),
                }
            default:
                return {
                    color: 'white',
                    icon: (
                        <InformationCircleIcon
                            aria-hidden="true"
                            className="size-5 text-blue-400"
                        />
                    ),
                }
        }
    }
    return (
        <div className={`rounded-md bg-${type(severity).color} p-1`}>
            <div className="flex">
                <div className="shrink-0">{type(severity).icon}</div>
                <div className="ml-3">
                    <p
                        className={`text-sm font-medium text-${
                            type(severity).color
                        }-500`}
                    >
                        {message}
                    </p>
                </div>
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            type="button"
                            className={`inline-flex rounded-md bg-${
                                type(severity).color
                            }-500/10 p-1.5 text-${type(severity).color}-500`}
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon aria-hidden="true" className="size-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
