// FirebaseContext.js
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import {
    getFirestore,
    collection,
    onSnapshot,
    where,
    query,
    doc,
    getDoc,
    orderBy,
} from 'firebase/firestore'
import { firebaseApp } from '../util/firebase'
import { useOrganisation } from './useOrganisation'

const EventsContext = createContext()

export const EventsProvider = ({ children }) => {
    const { selectedOid } = useOrganisation()
    const [eventsForOid, setEventsForOid] = useState([])
    const [event, setEvent] = useState(null) // New state for a single event

    const [loading, setLoading] = useState(true)
    const [eventLoading, setEventLoading] = useState(true) // New loading state for single event

    const db = getFirestore(firebaseApp)

    useEffect(() => {
        const fetchEventsForOid = async (oid) => {
            try {
                if (oid) {
                    let q = query(collection(db, 'events'))
                    q = query(q, where('oid', '==', oid))
                    q = query(q, where('deleted', '==', 0))
                    q = query(q, orderBy('date', 'asc'))

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        const fetchedData = querySnapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }))
                        setEventsForOid(fetchedData)
                        setLoading(false)
                    })

                    return unsubscribe
                }
            } catch (error) {
                console.log('Error loading realtime event updates.', error)
            }
        }
        fetchEventsForOid(selectedOid)
    }, [selectedOid, db])

    // Function to fetch a single event by its ID (eid)
    const fetchEventById = useCallback(async (eid) => {
        const db = getFirestore(firebaseApp)
        setEventLoading(true)

        try {
            const eventRef = doc(db, 'events', eid) // Reference to the event document
            const eventSnapshot = await getDoc(eventRef)

            if (eventSnapshot.exists()) {
                setEvent({
                    id: eventSnapshot.id,
                    ...eventSnapshot.data(),
                })
            } else {
                setEvent(null) // Event not found
            }
        } catch (error) {
            console.log('Error fetching event by ID:', error)
        } finally {
            setEventLoading(false)
        }
    }, [])

    return (
        <EventsContext.Provider
            value={{
                eventsForOid,
                event,
                eid: event?.id,
                loading,
                eventLoading,
                fetchEventById,
            }}
        >
            {children}
        </EventsContext.Provider>
    )
}

export const useEvents = () => useContext(EventsContext)
