import { createContext, useCallback, useContext, useState } from 'react'
import {
    getStorage,
    ref,
    uploadBytesResumable,
    listAll,
    getDownloadURL,
    getMetadata,
} from 'firebase/storage'
import { firebaseApp } from '../util/firebase'
import { useOrganisation } from './useOrganisation'
import { useEvents } from './useEvents'

const StorageContext = createContext()

export const StorageProvider = ({ children }) => {
    const { selectedOid } = useOrganisation()
    const { eid } = useEvents()
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const basePath = `uploads/${selectedOid}/${eid}/`
    const storage = getStorage(firebaseApp)

    // FUNCTIE OM BESTANDEN TE UPLOADEN
    const uploadFile = useCallback(
        async (filesArray) => {
            try {
                setLoading(true)
                const uploadPromises = filesArray.map(async (file) => {
                    const fileRef = ref(storage, `${basePath}${file.name}`)

                    const metadata = {
                        customMetadata: {
                            oid: selectedOid,
                            eid,
                        },
                        contentType: file.type,
                    }

                    const uploadTask = uploadBytesResumable(
                        fileRef,
                        file,
                        metadata
                    )

                    return new Promise((resolve, reject) => {
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                const progress =
                                    (snapshot.bytesTransferred /
                                        snapshot.totalBytes) *
                                    100
                                console.log(
                                    `Upload van ${file.name} is ${progress}% voltooid`
                                )
                            },
                            (error) => {
                                setError(error)
                                reject(error)
                            },
                            async () => {
                                const downloadURL = await getDownloadURL(
                                    fileRef
                                )
                                resolve({
                                    name: file.name,
                                    url: downloadURL,
                                })
                            }
                        )
                    })
                })

                // Wacht tot alle bestanden geüpload zijn
                const uploadedFiles = await Promise.all(uploadPromises)
                setFiles((prevFiles) => [...prevFiles, ...uploadedFiles])

                return uploadedFiles
            } catch (err) {
                setError(err)
                console.error('Upload failed', err)
            } finally {
                setLoading(false)
            }
        },
        [basePath, eid, selectedOid, storage]
    )

    // FUNCTIE OM BESTANDEN OP TE HALEN
    const fetchFiles = useCallback(async () => {
        setLoading(true)
        try {
            const storageRef = ref(storage, basePath)
            const fileList = await listAll(storageRef)

            const filePromises = fileList.items.map(async (item) => {
                const url = await getDownloadURL(item)
                const metadata = await getMetadata(item)
                return {
                    name: item.name,
                    url,
                    fullPath: item.fullPath,
                    metadata: metadata || {},
                }
            })
            const resolvedFiles = await Promise.all(filePromises)
            setFiles(resolvedFiles)
            setLoading(false)
        } catch (err) {
            setError(err)
            console.error('Error fetching files', err)
        }
    }, [basePath, storage])

    return (
        <StorageContext.Provider
            value={{
                files,
                uploadFile,
                fetchFiles,
                loading,
                error,
            }}
        >
            {children}
        </StorageContext.Provider>
    )
}

export const useStorage = () => useContext(StorageContext)
