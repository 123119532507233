import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useOrganisation } from '../hooks/useOrganisation'

const OrganisationSwitcher = () => {
    const [query, setQuery] = useState('')
    const navigate = useNavigate()
    const { setSelectedOid, selectedOrganisation, memberOrganisations } =
        useOrganisation()

    const filteredOrganisations =
        query === ''
            ? memberOrganisations
            : memberOrganisations.filter((organisation) => {
                  return organisation.name
                      .toLowerCase()
                      .includes(query.toLowerCase())
              })

    return memberOrganisations.length > 0 ? (
        <Combobox
            as="div"
            value={selectedOrganisation}
            onChange={(organisation) => {
                navigate('/dashboard')
                setQuery('')
                setSelectedOid(organisation.id)
            }}
        >
            <div className="relative mt-4">
                <ComboboxInput
                    className="block w-full rounded-md bg-transparent py-1.5 px-4 text-base text-silver-500 outline outline-1 -outline-offset-1 outline-silver-100  focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={() => setQuery('')}
                    displayValue={(organisation) => organisation?.name}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center text-silver-500 rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon
                        className="size-5 text-silver-400"
                        aria-hidden="true"
                    />
                </ComboboxButton>

                {filteredOrganisations.length > 0 && (
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full text-silver-500 overflow-auto rounded-md bg-midnight-800 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {filteredOrganisations.map((organisation) => (
                            <ComboboxOption
                                key={organisation.id}
                                value={organisation}
                                className="group relative cursor-default select-none cursor-pointer py-2 px-4 text-silver-500 data-[focus]:bg-blue-500 data-[focus]:text-white data-[focus]:outline-none"
                            >
                                <span className="block truncate group-data-[selected]:font-semibold">
                                    {organisation.name}
                                </span>
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>
                )}
            </div>
        </Combobox>
    ) : null
}

export default OrganisationSwitcher
