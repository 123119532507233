import { ArrowDownIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'

const EventProgressBar = ({ steps = [], subPath, section }) => {
    return (
        <nav aria-label="Progress">
            <ol className="divide-y divide-silver-100 rounded-md border border-silver-100 md:flex md:divide-y-0 text-silver-100">
                {steps.map((step, stepIdx) => (
                    <li key={step.name} className="relative md:flex md:flex-1">
                        {step.section === `${section || ''}` ? (
                            <Link
                                to={`${subPath}${step.to}`}
                                className="group flex w-full items-center "
                            >
                                <span className="flex items-center px-4 py-2 text-sm font-medium">
                                    <span className="flex size-10 shrink-0 items-center justify-center rounded-full bg-white-100 group-hover:bg-white-200">
                                        <ArrowDownIcon
                                            aria-hidden="true"
                                            className="size-6 text-white"
                                        />
                                    </span>
                                    <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium">
                                            {step.name}
                                        </span>
                                        {/* <span className="text-sm font-xs text-silver-600">
                      {step.description}
                    </span> */}
                                    </span>
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to={`${subPath}${step.to}`}
                                className="group flex items-center"
                            >
                                <span className="flex items-center px-4 py-2 text-sm font-medium text-silver-500 hover:text-silver-100">
                                    <span className="flex size-10 shrink-0 items-center justify-center rounded-full border-2 border-silver-300 group-hover:border-silver-400">
                                        {step.status === 'ready' ? (
                                            <CheckIcon
                                                aria-hidden="true"
                                                className="size-6 text-white"
                                            />
                                        ) : (
                                            <>
                                                <span className="text-silver-500 group-hover:text-silver-100">
                                                    {step.id}
                                                </span>
                                            </>
                                        )}
                                    </span>
                                    <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium">
                                            {step.name}
                                        </span>
                                        {/* <span className="text-sm font-xs text-silver-600">
                      {step.description}
                    </span> */}
                                    </span>
                                </span>
                            </Link>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div
                                    aria-hidden="true"
                                    className="absolute right-0 top-0 hidden h-full w-5 md:block"
                                >
                                    <svg
                                        fill="none"
                                        viewBox="0 0 22 80"
                                        preserveAspectRatio="none"
                                        className="size-full text-silver-300"
                                    >
                                        <path
                                            d="M0 -2L20 40L0 82"
                                            stroke="currentcolor"
                                            vectorEffect="non-scaling-stroke"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </>
                        ) : null}
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default EventProgressBar
