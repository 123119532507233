import { useQuery, hashQueryKey, QueryClient } from 'react-query'
import {
    getFirestore,
    onSnapshot,
    collection,
    query,
    where,
    orderBy,
    getDoc,
    doc,
    addDoc,
    setDoc,
} from 'firebase/firestore'
import { firebaseApp } from './firebase'

// Initialize Firestore
const db = getFirestore(firebaseApp)

// React Query client
const client = new QueryClient()

/**** EVENTS ****/

export function useEventOnce(eventId) {
    return useQuery(
        ['event', { eventId }],
        // When fetching once there is no need to use `createQuery` to setup a subscription
        // Just fetch normally using `getDoc` so that we return a promise
        () => getDoc(doc(db, 'events', eventId)).then(format),
        { enabled: !!eventId }
    )
}

export const useEventsBy = (oid) => {
    return useQuery(
        ['events', { oid }],
        createQuery(() =>
            query(
                collection(db, 'events'),
                where('oid', '==', oid),
                where('deleted', '==', 0),
                orderBy('date')
            )
        ),
        { enabled: !!oid }
    )
}

export function createEvent(data) {
    return addDoc(collection(db, 'events'), {
        ...data,
    })
}

export function updateEvent(eid, data) {
    // return updateDoc(doc(db, 'events', eid), data)
    return setDoc(doc(db, 'events', eid), data, { merge: true })
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {}

const createQuery = (getRef) => {
    // Create a query function to pass to `useQuery`
    return async ({ queryKey }) => {
        let unsubscribe
        let firstRun = true
        // Wrap `onSnapshot` with a promise so that we can return initial data
        const data = await new Promise((resolve, reject) => {
            unsubscribe = onSnapshot(
                getRef(),
                // Success handler resolves the promise on the first run.
                // For subsequent runs we manually update the React Query cache.
                (response) => {
                    const data = format(response)
                    if (firstRun) {
                        firstRun = false
                        resolve(data)
                    } else {
                        client.setQueryData(queryKey, data)
                    }
                },
                // Error handler rejects the promise on the first run.
                // We can't manually trigger an error in React Query, so on a subsequent runs we
                // invalidate the query so that it re-fetches and rejects if error persists.
                (error) => {
                    if (firstRun) {
                        firstRun = false
                        reject(error)
                    } else {
                        client.invalidateQueries(queryKey)
                    }
                }
            )
        })

        // Unsubscribe from an existing subscription for this `queryKey` if one exists
        // Then store `unsubscribe` function so it can be called later
        const queryHash = hashQueryKey(queryKey)
        unsubs[queryHash] && unsubs[queryHash]()
        unsubs[queryHash] = unsubscribe

        return data
    }
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
    if (
        type === 'observerRemoved' &&
        query.getObserversCount() === 0 &&
        unsubs[query.queryHash]
    ) {
        // Call stored Firestore unsubscribe function
        unsubs[query.queryHash]()
        delete unsubs[query.queryHash]
    }
})

// Format Firestore response
function format(response) {
    // Converts doc into object that contains data and `doc.id`
    const formatDoc = (doc) => ({ id: doc.id, ...doc.data() })
    if (response.docs) {
        // Handle a collection of docs
        return response.docs.map(formatDoc)
    } else {
        // Handle a single doc
        return response.exists() ? formatDoc(response) : null
    }
}
