import React, { useState } from 'react'
import Icon from './Icon'
import flashImage from '../assets/images/svg/computer-content.png'

const NewsFlash = ({ headline, tagline, label, hide }) => {
    const [show, setHide] = useState(hide)
    return !show ? (
        <div className="rounded bg-gradient-to-br from-red to-pink m-4">
            <div className="relative">
                <div className="absolute right-4 top-2">
                    <button
                        size="sm"
                        variant="solid"
                        className="uppercase text-silver hover:text-white cursor-pointer"
                        onClick={() => setHide(true)}
                    >
                        <Icon name="cross" size="9" />
                    </button>
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center text-white">
                <div className="p-8 md:flex-none w-48 ">
                    <img
                        className="inline max-h-36"
                        src={flashImage}
                        alt="Calendar 7"
                    />
                </div>
                <div className="grow px-4">
                    <div className="text-4xl px-2 font-bold text-center md:text-left">
                        <h1 className="">{headline}</h1>
                    </div>
                    <div className="px-0 md:px-4 pb-8 text-2xl font-thin text-center md:text-left">
                        <h4 className="">{tagline}</h4>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default NewsFlash
