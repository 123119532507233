import React from 'react'
import {
    PuzzlePieceIcon,
    UsersIcon,
    CloudIcon,
    AdjustmentsHorizontalIcon,
    PlayCircleIcon,
    VideoCameraIcon,
} from '@heroicons/react/24/solid'
import Section from './Section'
import SectionHeader from './SectionHeader'
import FeatureIcon2 from './FeatureIcon2'

function FeaturesSection(props) {
    const features = [
        {
            title: 'Easy Presets',
            description:
                'Easy Presets will give you hands-on control of your audio, video, camera and lighting setup.',
            icon: PlayCircleIcon,
            iconColor: 'orange',
        },
        {
            title: 'Customizable',
            description:
                "Depending on what is needed and when it's needed, the right controls are offered to the user. This can be set dynamically using the preproduction platform.",
            icon: AdjustmentsHorizontalIcon,
            iconColor: 'red',
        },

        {
            title: 'Cloud Service',
            description:
                'Our Envio Cloud service offers a user friendly portal and API to schedule, manage and interact with your events.',
            icon: CloudIcon,
            iconColor: 'cyan',
        },
        {
            title: 'Powerfull Communication',
            description:
                'Livestream and video communication for internal and external communication services are provided at broadcast level.',
            icon: VideoCameraIcon,
            iconColor: 'purple',
        },
        {
            title: 'Integration',
            description:
                'Envio services integrate with your existing IP-based studio and venue equipment. The Envio Cloud API can be integrated within any internal portal.',
            icon: PuzzlePieceIcon,
            iconColor: 'blue',
        },
        {
            title: 'On-Demand Support',
            description:
                'Our team of technical experts are ready to assist remotely, at request. Your presenters and guest receive the support and guidance they need.',
            icon: UsersIcon,
            iconColor: 'pink',
        },
    ]

    return (
        <Section
            size={props.size}
            bgColor={props.bgColor}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            textColor={props.textColor}
        >
            <div className="space-y-16 container">
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    strapline={props.strapline}
                    className="text-center"
                />
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5">
                    {features.map((feature, index) => (
                        <div
                            className="group p-5 transition ease-out duration-200 rounded-2xl text-center"
                            key={index}
                        >
                            <FeatureIcon2
                                color={feature.iconColor}
                                size="large"
                                className="mb-12"
                            >
                                <feature.icon />
                            </FeatureIcon2>
                            <h4 className="text-lg font-bold mb-2">
                                {feature.title}
                            </h4>
                            <p className="leading-relaxed text-silver-600">
                                {feature.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </Section>
    )
}

export default FeaturesSection
