import IndexPage from '.'
import AboutPage from './about'
import AuthPage from './auth'
import ContactPage from './contact'
import DashboardPage from './dashboard'
import FaqPage from './faq'

import LegalPage from './legal'
import FirebaseActionPage from './firebase-action'
import { createBrowserRouter } from 'react-router-dom'
import NotFoundPage from './404'
import Event from './event'
import EventsPage from './events'
import ContentPage from './event/content'
import ProtectedRoute from '../util/protected-route'
import DashboardLayout from './layouts/DashboardLayout'
import DefaultLayout from './layouts/DefaultLayout'
import DashboardSettingsPage from './settings'
import EventLayout from './layouts/EventLayout'
import { OrganisationProvider } from '../hooks/useOrganisation'
import { EventsProvider } from '../hooks/useEvents'
import { StorageProvider } from '../hooks/useStorage'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            { index: true, element: <IndexPage /> },
            { path: 'about', element: <AboutPage /> },
            { path: 'faq', element: <FaqPage /> },
            { path: 'contact', element: <ContactPage /> },
            { path: 'legal/:section', element: <LegalPage /> },
            { path: 'auth/:type', element: <AuthPage /> },
            { path: 'firebase-action', element: <FirebaseActionPage /> },
            { path: '*', element: <NotFoundPage /> },
        ],
    },
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <OrganisationProvider>
                    <EventsProvider>
                        <DashboardLayout />
                    </EventsProvider>
                </OrganisationProvider>
            </ProtectedRoute>
        ),
        children: [
            {
                path: 'dashboard',
                element: <DashboardPage />,
                handle: { section: 'content' },
            },
            {
                path: 'events',
                element: <EventsPage />,
                handle: { section: 'events' },
            },
            {
                path: 'event',
                element: (
                    <StorageProvider>
                        <EventLayout />
                    </StorageProvider>
                ),
                children: [
                    {
                        path: ':eid',
                        element: <Event />,
                        handle: { section: 'details' },
                    },
                    {
                        path: ':eid/content',
                        element: <ContentPage />,
                        handle: { section: 'content' },
                    },
                    {
                        path: ':eid/speakers',
                        element: <Event />,
                        handle: { section: 'speakers' },
                    },
                    {
                        path: ':eid/script',
                        element: <Event />,
                        handle: { section: 'script' },
                    },
                    {
                        path: ':eid/broadcast',
                        element: <Event />,
                        handle: { section: 'broadcast' },
                    },
                ],
            },
            {
                path: 'settings',
                element: <DashboardSettingsPage />,
                handle: { section: 'settings' },
            },
        ],
    },
])
