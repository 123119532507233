import React from 'react'
import Meta from './../components/Meta'
import LegalSection from './../components/LegalSection'
import { useParams } from './../util/router'

function LegalPage(props) {
    const params = useParams()

    return (
        <>
            <Meta title="Legal" />
            <LegalSection
                size="md"
                bgColor="bg-white"
                section={params.section}
                key={params.section}
            />
        </>
    )
}

export default LegalPage
