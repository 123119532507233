import { useRef, useEffect } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import { firebaseApp } from './firebase'
import { Timestamp } from 'firebase/firestore'

const auth = getAuth(firebaseApp)

// Make an API request to `/api/{path}`
export async function apiRequest(path, method = 'GET', data) {
    const accessToken = auth.currentUser
        ? await auth.currentUser.getIdToken()
        : undefined

    return fetch(`/api/${path}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: data ? JSON.stringify(data) : undefined,
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'error') {
                // Automatically signout user if accessToken is no longer valid
                if (response.code === 'auth/invalid-user-token') {
                    signOut(auth)
                }

                throw new CustomError(response.code, response.message)
            } else {
                return response.data
            }
        })
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = 'GET', data) {
    return fetch(url, {
        method: method,
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: data ? JSON.stringify(data) : undefined,
    }).then((response) => response.json())
}

// Create an Error with custom message and code
export function CustomError(code, message) {
    const error = new Error(message)
    error.code = code
    return error
}

// Hook that returns previous value of state
export function usePrevious(state) {
    const ref = useRef()
    useEffect(() => {
        ref.current = state
    })
    return ref.current
}

export function firebaseTimestamp() {
    return Timestamp.now()
}

export function convertToFirebaseTimestamp(timestamp) {
    return Timestamp.fromDate(new Date(timestamp))
}
