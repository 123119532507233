import React, { useEffect } from 'react'

// import useFirebaseStorage from '../../../hooks/useFirebaseStorage'

// import { useForm } from 'react-hook-form'
// import { useEvents } from '../../../hooks/useEvents'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useStorage } from '../../../hooks/useStorage'
import PageLoader from '../../../components/PageLoader'
import NoItemsToShow from '../../../components/NoItemsToShow'
import MediaThumbnail from '../../../components/MediaThumbnail'

const ContentPage = () => {
    const { files, fetchFiles, loading, error } = useStorage()
    // const { event } = useEvents()
    // const reactHookForm = useForm({
    //     defaultValues: { ...event },
    //     mode: 'all',
    // })

    // const { handleSubmit } = reactHookForm

    // const onSubmit = async (formData) => {
    //     console.log(formData)
    // }

    useEffect(() => {
        fetchFiles()
    }, [fetchFiles])

    return loading ? (
        <PageLoader />
    ) : files.length === 0 ? (
        <NoItemsToShow unit="media" />
    ) : (
        <form
            // onSubmit={handleSubmit(onSubmit)}
            className="dark:bg-midnight-600 text-silver shadow-sm drop-shadow-md sm:rounded-xl md:col-span-2 mx-4"
        >
            <div className="p-4">
                <div className="space-y-10 divide-y divide-silver">
                    <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
                        <div className="px-4 sm:px-4 py-2">
                            <h2 className="text-base/7 font-semibold text-silver-500">
                                Content Manager
                            </h2>
                            <p className="mt-1 text-sm/6 text-silver-700">
                                Upload, manage and edit your event specific
                                content here.
                            </p>
                            {error && (
                                <p className="mt-4 text-sm/6 text-red-7">
                                    {error}
                                </p>
                            )}
                        </div>

                        <div className="px-4">
                            <div className="max-w-2xl mt-4 gap-x-6 gap-y-4 ">
                                <ul className="divide-y divide-silver-100">
                                    {files.map((file, index) => (
                                        <li
                                            key={`${file.fullPath}-${index}`}
                                            className="flex items-center justify-between gap-x-6 p-4 sm:rounded-xl bg-midnight-500/90"
                                        >
                                            <div className="min-w-0 ">
                                                <div className="flex items-center justify-center gap-x-3 text-l font-semibold text-silver">
                                                    <div>
                                                        <MediaThumbnail
                                                            file={file}
                                                            size={'w-16 h-16'}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        {file.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-none items-center gap-x-4">
                                                {/* <a
                                                    href={file.href}
                                                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                                >
                                                    View File
                                                    <span className="sr-only">
                                                         {file.name}
                                                    </span>
                                                </a> */}
                                                <Menu
                                                    as="div"
                                                    className="relative flex-none"
                                                >
                                                    <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                                        <span className="sr-only">
                                                            Open options
                                                        </span>
                                                        <EllipsisVerticalIcon
                                                            aria-hidden="true"
                                                            className="size-5"
                                                        />
                                                    </MenuButton>
                                                    <MenuItems
                                                        transition
                                                        className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                                    >
                                                        <MenuItem>
                                                            <a
                                                                href={file.href}
                                                                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                            >
                                                                Edit
                                                                <span className="sr-only">
                                                                    ,{' '}
                                                                    {file.name}
                                                                </span>
                                                            </a>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <a
                                                                href={file.href}
                                                                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                            >
                                                                Move
                                                                <span className="sr-only">
                                                                    ,{' '}
                                                                    {file.name}
                                                                </span>
                                                            </a>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <a
                                                                href={file.href}
                                                                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                            >
                                                                Delete
                                                                <span className="sr-only">
                                                                    ,{' '}
                                                                    {file.name}
                                                                </span>
                                                            </a>
                                                        </MenuItem>
                                                    </MenuItems>
                                                </Menu>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        //         <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        //             {files.map((file, index) => (
        //                 <div key={index} className="relative group">
        //                     {file.name.match(/\.(jpeg|jpg|png|gif)$/) ? (
        //                         <img
        //                             src={file.url}
        //                             className="w-full h-32 object-cover rounded-lg shadow"
        //                             alt="Upload preview"
        //                         />
        //                     ) : (
        //                         <video
        //                             className="w-full h-32 rounded-lg shadow"
        //                             controls
        //                         >
        //                             <source src={file.url} type="video/mp4" />
        //                         </video>
        //                     )}
        //                 </div>
        //             ))}
        //         </div>
        //     )}
        // </div>
    )
}

export default ContentPage
