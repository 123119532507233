const nav = {
    primary: {
        active: 'bg-midnight-400 dark:bg-midnight-700 dark:hover:bg-midnight-900 dark:text-white',
        hover: 'hover:bg-midnight-300 dark:hover:bg-midnight-600 dark:text-white',
    },
    secondary: {
        active: 'text-midnight bg-white-200 hover:bg-white-400  dark:bg-midnight-600  dark:hover:bg-midnight-900 dark:text-white',
        hover: 'text-midnight hover:bg-silver-200 dark:hover:bg-midnight-600 dark:text-white',
    },
}

const dropdown = {
    menu: 'absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-silver-100 dark:bg-midnight-800 py-2 shadow-lg ring-1 ring-silver-100/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in ',
    item: 'block flex-1 px-3 py-1 text-sm/6 text-silver hover:bg-blue-500 hover:text-white data-[focus]:bg-blue data-[focus]:outline-none',
}

const formfield = {
    input: 'text-base bg-transparent block w-full rounded-md px-3 py-1.5 mb-2 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue sm:text-sm/6',
    date: 'text-base bg-transparent block w-full rounded-md px-3 py-1.5 mb-2 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue sm:text-sm/6 ',
    textarea:
        'text-base bg-transparent block w-full rounded-md px-3 py-1.5 mb-2 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue sm:text-sm/6',
    label: 'text-base font-medium',
    select: 'col-start-1 row-start-1 w-full appearance-none rounded-md mb-2 bg-transparent py-1.5 pl-3 pr-8 text-base focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6',
    switch: 'group relative inline-flex h-6 w-11 shrink-0 cursor-pointer mb-2 rounded-full border-2 border-transparent bg-silver-600 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 data-[checked]:bg-blue-400',
    description: 'text-base',
}

export { nav, dropdown, formfield }
