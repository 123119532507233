import React, { useState } from 'react'
import {
    XMarkIcon,
    ArrowDownTrayIcon,
    ArrowsPointingOutIcon,
} from '@heroicons/react/24/outline' // Heroicons import

const MediaThumbnail = ({ file, size = 'w-32 h-32' }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleModalOpen = () => {
        setIsModalOpen(true)
    }

    const handleModalClose = () => {
        setIsModalOpen(false)
    }

    return (
        <div className="relative group cursor-pointer">
            {/* Thumbnail - Gecentreerd en Vierkant */}
            <div className={`relative ${size}`}>
                <img
                    src={file.url}
                    alt={file.name}
                    className="object-cover w-full h-full rounded-lg" // Zorgt voor gecentreerde uitsnede
                    onClick={handleModalOpen}
                />
            </div>

            {/* Hover Overlay met Icoon (Klikbaar) */}
            <div
                className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg cursor-pointer"
                onClick={handleModalOpen} // Klikken op de overlay opent de modal
            >
                <div className="w-12 h-12 bg-black rounded-full flex items-center justify-center">
                    <ArrowsPointingOutIcon className="text-white w-6 h-6" />{' '}
                    {/* Heroicons icoon */}
                </div>
            </div>

            {/* Modal (Voorwaardelijk Weergegeven) */}
            {isModalOpen && (
                <div
                    className="fixed inset-0 z-50 flex justify-center items-center bg-midnight-600 bg-opacity-90" // Modal gecentreerd
                    onClick={handleModalClose}
                >
                    {/* Modal Inhoud */}
                    <div
                        className="relative top-24 bg-midnight-700 p-2 rounded-lg max-w-lg w-full z-10"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Afbeelding of Video Preview */}
                        <div className="relative">
                            {file.metadata.contentType.includes('image') ? (
                                <img
                                    src={file.url}
                                    alt={file.name}
                                    className="w-full h-auto rounded-lg"
                                />
                            ) : file.metadata.contentType.includes('video') ? (
                                <video
                                    controls
                                    className="w-full h-auto rounded-lg"
                                >
                                    <source
                                        src={file.url}
                                        type={file.metadata.contentType}
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <div className="w-full h-64 bg-gray-300 rounded-lg flex items-center justify-center text-center">
                                    <span className="text-gray-700">
                                        Data File
                                    </span>
                                </div>
                            )}
                        </div>
                        {/* Overlay met Sluit en Download Knoppen boven de Foto */}
                        <div className="absolute text-silver top-4 right-4 flex space-x-4 z-99">
                            {/* Download Knop (Heroicons ArrowDownTrayIcon) */}
                            <a
                                href={file.url}
                                download={file.name}
                                className="text-silver hover:text-silver-700"
                            >
                                <ArrowDownTrayIcon
                                    aria-hidden="true"
                                    className="size-5"
                                />
                            </a>
                            {/* Sluitknop (Heroicons XMarkIcon) */}
                            <button
                                onClick={handleModalClose}
                                className="text-silver hover:text-silver-700"
                            >
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="size-5"
                                />
                            </button>
                        </div>
                        {/* Bestandsnaam boven de Foto */}
                        <div className="text-white text-center text-lg font-semibold shadow-sm mt-2">
                            {file.name}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MediaThumbnail
