import React from 'react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import Section from './Section'
import SectionHeader from './SectionHeader'
import Button from './Button'

function FaqSection(props) {
    const faqItems = [
        {
            question: 'What is an EMS?',
            answer: 'EMS is an acronym for Event Management System. This is the name for a bundle of 19-inch equipment, installed at customer site. Usally this system will be installed into the control room of a venue. The EMS is managed and serviced by the Envio team and is operated remotely.',
        },
        {
            question: 'What is ECS?',
            answer: "ECS is an acronym for Envio Cloud Service. This is the name for our event schedule, manage and (pre)production platform. It's a cloud service that's available over internet. This platform gives you tools and control you need to schedule ahead, preproduce and take control over branding, speakers, content management and script. It extends the easy of use of the EMS, but it can also be used without EMS.",
        },
        {
            question: 'Could you do a livestream remotely?',
            answer: "Yes, if you want to stream your event live, this service is perfect for you. We control your venue camera's, switching and recording for you. This way you only need to focus on the local presentation.",
        },
        {
            question: 'How does Envio take local control?',
            answer: 'Your technical setup will be extended with our Event Management System (EMS). The EMS connects to the essential elements in your control room and makes them work in harmony together. Local control is still possible using the Envio Easy Presets or using manual overide on the technical equipment itself.',
        },
        {
            question: 'Do I need to buy new equipment?',
            answer: 'Not necesarily, as long as the equipment is IP-based or easy to integrate with our EMS. If the equipment is not supporting IP connectivity, we will provide some advise. The Envio service works at its best if everything is connected.',
        },
        {
            question: 'What does the Envio Service cost?',
            answer: 'Due to the high customization for each client, we can only provide custom offers. Our sales team will help you getting a clear costs overview',
        },
        {
            question: 'What kind of connections does Envio use?',
            answer: 'Our Envio EMS connects to the Envio Cloud and Envio Production network over your local internet connection. This internet connection requires to be free of outbound firewalls and requires a minimal of 500mbps uplink and 500mbps downlink speed.',
        },

        {
            question: 'What happens if the remote connection fails?',
            answer: "If for some reason our remote connection drops, it's high likely caused by your local internet connection. Our infrastructure is setup redundently. In other words: we're not depended on a single connection. In event of a remote connection drop, our local EMS system will continue working in its last state. We will pick it up after it automatically reconnects. Small drops of connection loss won't cause any issue noticable.",
        },

        {
            question: 'How secure is the Envio service?',
            answer: "The Envio service runs on its own network and uses an encrypted VPN connection to communicate between our Envio Production Platform and the local EMS. Within the production network, we don't transfer sensitive data, other than video streams. These streams won't leave the building in case of an internal event.",
        },
        {
            question: 'What is EPN?',
            answer: 'EPN is an acronym for Envio Production Network. This is the name of the network for the technical team, such as directors, camera operators, audio technicians and network operators are connected to. ',
        },
    ]

    return (
        <Section
            size={props.size}
            bgColor={props.bgColor}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            textColor={props.textColor}
        >
            <div className="space-y-12 container">
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    strapline={props.strapline}
                    className="text-center"
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
                    {faqItems.map((item, index) => (
                        <div className="prose prose-blue" key={index}>
                            <h4>{item.question}</h4>
                            <p>{item.answer}</p>
                        </div>
                    ))}
                </div>

                {props.showSupportButton && (
                    <div className="text-center">
                        <Button
                            href={props.supportUrl}
                            target="_blank"
                            size="lg"
                            variant="simple"
                            startIcon={
                                <ArrowTopRightOnSquareIcon className="opacity-50 inline-block w-5 h-5" />
                            }
                        >
                            Contact Us
                        </Button>
                    </div>
                )}
            </div>
        </Section>
    )
}

export default FaqSection
