import React from 'react'
import { nav } from './class-groups'
import { Link, useLocation } from 'react-router-dom'
import classNames from './class-names'

const SubMenuNavigation = ({ menuObject, subPath, border }) => {
    const location = useLocation()

    return (
        <nav
            className={`flex overflow-x-auto ${
                border && 'border-b border-midnight/10 dark:border-white/10'
            } py-4`}
        >
            <ul className="flex min-w-full flex-none gap-x-6 px-4 text-sm/6 font-semibold sm:px-4 lg:px-4">
                {(menuObject || []).map((item) => (
                    <li key={item.name}>
                        <Link
                            to={`${subPath}${item.to}`}
                            className={classNames(
                                location.pathname === item.to
                                    ? nav.secondary.active
                                    : nav.secondary.hover,
                                'group flex gap-x-3 rounded-md py-1 px-4 text-sm/6'
                            )}
                        >
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default SubMenuNavigation
