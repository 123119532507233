import React from 'react'
import Meta from '../../components/Meta'
import DashboardSection from '../../components/DashboardSection'

const DashboardPage = () => {
    return (
        <>
            <Meta title="Dashboard" />
            <DashboardSection
                title="Dashboard"
                subtitle=""
                strapline=""
                size="lg"
                bgColor="bg-white dark:bg-midnight"
                textColor="text-black dark:text-silver"
            />
        </>
    )
}

export default DashboardPage
