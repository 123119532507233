import React from 'react'

import Meta from '../../components/Meta'
import EventForm from '../../components/EventForm'

const Event = (props) => {
    return (
        <>
            <Meta title="Event" />
            <EventForm />
        </>
    )
}

export default Event
