'use client'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Icon from './Icon'
import { nav } from './class-groups'
import classNames from './class-names'
import OrganisationSwitcher from './OrganisationSwitcher'

const navigation = [
    { name: 'Dashboard', to: ['/dashboard'], icon: 'dashboard', current: true },
    {
        name: 'Events',
        to: ['/events', '/events/archive'],
        icon: 'calendar',
        current: false,
    },
]

function DashboardNavigation(props) {
    const location = useLocation()

    return (
        <nav className="flex flex-1 flex-col">
            <ul className=" flex flex-1 flex-col gap-y-4 ">
                <li>
                    <OrganisationSwitcher />

                    {/* Separator */}
                </li>
                <div
                    aria-hidden="true"
                    className="border-b border-silver-500 dark:border-white/10"
                />
                <li>
                    <ul className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                            <li key={item.name}>
                                <Link
                                    to={item.to[0]}
                                    className={classNames(
                                        item.to.includes(location.pathname)
                                            ? `${nav.primary.active}`
                                            : `${nav.primary.hover}`,
                                        'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                                    )}
                                >
                                    <Icon
                                        name={item.icon}
                                        aria-hidden="true"
                                        className="size-6 shrink-0"
                                        size={16}
                                    />
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>

                <li className="mt-auto">
                    <Link
                        to="/settings"
                        className={classNames(
                            location.pathname === '/settings'
                                ? `${nav.primary.active}`
                                : `${nav.primary.hover}`,
                            'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                        )}
                    >
                        <Icon
                            name="settings"
                            aria-hidden="true"
                            className="size-6 shrink-0"
                            size={16}
                        />
                        Settings
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default DashboardNavigation
