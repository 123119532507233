import React from 'react'
import Meta from './../components/Meta'
import { useNavigate } from 'react-router-dom'

function NotFoundPage(props) {
    const navigate = useNavigate()
    return (
        <>
            <Meta title="404" />
            <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-indigo-600">
                        404
                    </p>
                    <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                        Page <code>{props.location?.pathname}</code> not found
                    </h1>
                    <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                        Sorry, we couldn’t find the page you’re looking for.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <button
                            onClick={() => navigate(-1)}
                            className="rounded-md bg-midnight-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-midnight-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midnight-600"
                        >
                            Go back
                        </button>
                        <a
                            href="mailto:support@envio.live"
                            className="text-sm font-semibold text-gray-900"
                        >
                            Contact support{' '}
                            <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </div>
            </main>
        </>
    )
}

export default NotFoundPage
