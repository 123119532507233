import React, { useEffect } from 'react'
import EventHeader from '../../components/EventHeader'
import {
    Outlet,
    useOutletContext,
    useParams,
    useMatches,
    useNavigate,
} from 'react-router-dom'
import EventProgressBar from '../../components/EventProgressBar'
import NoItemsToShow from '../../components/NoItemsToShow'
import PageLoader from '../../components/PageLoader'
import { useEvents } from '../../hooks/useEvents'
import { useAuth } from '../../util/auth'
import steps from '../../datasets/event-steps.json'
import {
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
} from '@heroicons/react/24/outline'
import Button from '../../components/Button'

const EventLayout = () => {
    const { user } = useAuth()
    const navigate = useNavigate()
    const params = useParams()
    const matches = useMatches()
    const { setSidebarOpen, setNotification } = useOutletContext()
    const eventObj = useEvents()
    const { event, eventLoading, fetchEventById } = eventObj

    const eventId = params.eid
    const section = matches[matches.length - 1]?.handle?.section
    const subPath = `/event/${eventId}`
    const currentStepIndex = steps.findIndex((step) => {
        return step.section === section
    })

    useEffect(() => {
        if (eventId) {
            fetchEventById(eventId) // Fetch the event when eid changes
        }
        setSidebarOpen(false)
    }, [eventId, fetchEventById, setSidebarOpen])

    if (eventLoading) {
        return <PageLoader />
    }

    if (!event) {
        return <NoItemsToShow unit="data" />
    }

    const handleNextPrevious = (direction) => {
        return steps[currentStepIndex + direction].to
    }

    return (
        <main className="text-midnight dark:text-silver-100">
            <div>
                <header className="sticky flex flex-col  ">
                    <EventHeader className="flex-1" eventDetails={event} />
                    <div className="px-4 mt-4">
                        <EventProgressBar
                            className="flex-1"
                            subPath={subPath}
                            section={section}
                            steps={steps}
                        />
                    </div>
                </header>
                <div className="mt-4 mb-4">
                    <Outlet
                        context={{
                            setNotification,
                            userObj: user,
                            eventObj,
                            event: eventObj.event,
                        }}
                    />
                </div>
                <div className="mx-4 flex justify-between">
                    {currentStepIndex > 0 ? (
                        <Button
                            size="sm"
                            className="bg-midnight border-midnight-400 hover:bg-midnight-300"
                            startIcon={
                                <ArrowLeftCircleIcon className="inline-block w-5 h-5" />
                            }
                            onClick={() => {
                                navigate(`${subPath}${handleNextPrevious(-1)}`)
                            }}
                        >
                            Previous
                        </Button>
                    ) : (
                        <span></span>
                    )}
                    {currentStepIndex < steps.length - 1 && (
                        <Button
                            size="sm"
                            className="bg-midnight border-midnight-400 hover:bg-midnight-300"
                            endIcon={
                                <ArrowRightCircleIcon className="inline-block w-5 h-5" />
                            }
                            onClick={() => {
                                navigate(`${subPath}${handleNextPrevious(1)}`)
                            }}
                        >
                            Next
                        </Button>
                    )}
                </div>
            </div>
        </main>
    )
}

export default EventLayout
