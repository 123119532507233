import { Controller } from 'react-hook-form'
import classNames from './class-names'
import { formfield } from './class-groups'
import { Field, Label, Switch } from '@headlessui/react'

const FormField = ({ form, reactHookForm, onChange }) => {
    const {
        register,
        control,
        formState: { errors },
    } = reactHookForm

    switch (form.type) {
        case 'textarea':
            return (
                <div className="col-span-full">
                    {form.label && (
                        <label
                            htmlFor={form.name}
                            className={classNames(
                                formfield.label,
                                'block text-sm/6',
                                form.labelClass
                            )}
                        >
                            {form.label}
                        </label>
                    )}
                    {form.description && (
                        <p
                            className={classNames(
                                formfield.description,
                                'block text-sm/6',
                                form.descriptionClass
                            )}
                        >
                            {form.description}
                        </p>
                    )}
                    <div className="w-full">
                        <textarea
                            {...register(form.name, {
                                required: form.required,
                            })}
                            id={form.name}
                            autoComplete={form.autoComplete}
                            type={form.type}
                            placeholder={form.placeholder}
                            defaultValue={form.defaultValue}
                            rows={form.rows}
                            className={classNames(
                                formfield.textarea,
                                form.bodyClass
                            )}
                        />
                        {errors[form.name] && (
                            <div className="text-sm text-red py-2 pl-2">
                                <span>This field is required.</span>
                            </div>
                        )}
                    </div>
                </div>
            )
        case 'select':
            return (
                <div className="sm:col-span-4">
                    {form.label && (
                        <label
                            htmlFor={form.name}
                            className={classNames(
                                formfield.label,
                                'block text-sm/6',
                                form.labelClass
                            )}
                        >
                            {form.label}
                        </label>
                    )}
                    <div className="">
                        <div className="flex items-center rounded-md">
                            <select
                                defaultValue={form.defaultValue}
                                {...register(form.name, {
                                    required: form.required,
                                })}
                                id={form.name}
                                autoComplete={form.autoComplete || 'off'}
                                type={form.type}
                                autoFocus={form.focus}
                                placeholder={form.placeholder}
                                className={classNames(
                                    formfield.select,
                                    form.bodyClass
                                )}
                            >
                                {form.options.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                        selected={option.selected}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {errors[form.name] && (
                            <div className="text-sm text-red py-2 pl-2">
                                <span>This field is required.</span>
                            </div>
                        )}
                    </div>
                </div>
            )
        case 'number':
            return (
                <div className="sm:col-span-4">
                    {form.label && (
                        <label
                            htmlFor={form.name}
                            className={classNames(
                                formfield.label,
                                'block text-sm/6',
                                form.labelClass
                            )}
                        >
                            {form.label}
                        </label>
                    )}
                    <div className="">
                        <div className="flex items-center rounded-md ">
                            <input
                                defaultValue={form.defaultValue}
                                {...register(form.name, {
                                    required: form.required,
                                    valueAsNumber: true,
                                })}
                                id={form.name}
                                type={form.type}
                                autoFocus={form.focus}
                                autoComplete={form.autoComplete || 'off'}
                                placeholder={form.placeholder}
                                min={form.min}
                                max={form.max}
                                step={form.step}
                                className={classNames(
                                    formfield.input,
                                    form.bodyClass
                                )}
                            />
                        </div>
                        {errors[form.name] && (
                            <div className="text-sm text-red py-2 pl-2">
                                <span>This field is required.</span>
                            </div>
                        )}
                    </div>
                </div>
            )
        case 'switch':
            return (
                <div className="my-2 sm:col-span-4">
                    <div className="mt-2">
                        <div className="flex items-center rounded-md">
                            <Controller
                                control={control}
                                name={form.name}
                                id={form.name}
                                autoComplete={form.autoComplete || 'off'}
                                defaultValue={form.defaultValue}
                                rules={{ required: form.required }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => {
                                    return (
                                        <Field className="flex items-center">
                                            <Switch
                                                checked={value}
                                                onChange={onChange}
                                                className={classNames(
                                                    formfield.switch,
                                                    form.bodyClass
                                                )}
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                />
                                            </Switch>
                                            <Label
                                                as="span"
                                                className="ml-3 text-sm"
                                            >
                                                <span
                                                    className={classNames(
                                                        formfield.label,
                                                        'block text-sm/6',
                                                        form.labelClass
                                                    )}
                                                >
                                                    {form.label}
                                                </span>
                                            </Label>
                                        </Field>
                                    )
                                }}
                            />
                        </div>
                        {errors[form.name] && (
                            <div className="text-sm text-red py-2">
                                <span>This field is required.</span>
                            </div>
                        )}
                    </div>
                </div>
            )
        case 'date':
            return (
                <div className="sm:col-span-4">
                    {form.label && (
                        <label
                            htmlFor={form.name}
                            className={classNames(
                                formfield.label,
                                'block text-sm/6',
                                form.labelClass
                            )}
                        >
                            {form.label}
                        </label>
                    )}
                    <div className="">
                        <div className="flex items-center rounded-md relative">
                            <input
                                defaultValue={form.defaultValue}
                                {...register(form.name, {
                                    required: form.required,
                                })}
                                id={form.name}
                                type={form.type}
                                autoFocus={form.focus}
                                autoComplete={form.autoComplete || 'off'}
                                placeholder={form.placeholder}
                                className={classNames(
                                    formfield.date,
                                    '',
                                    form.bodyClass
                                )}
                            />
                            <div className="absolute inset-y-0 right-2 bottom-2 flex items-center pointer-events-none">
                                <svg
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        {errors[form.name] && (
                            <div className="text-sm text-red py-2 pl-2">
                                <span>This date is required.</span>
                            </div>
                        )}
                    </div>
                </div>
            )

        default:
            return (
                <div className="sm:col-span-4">
                    {form.label && (
                        <label
                            htmlFor={form.name}
                            className={classNames(
                                formfield.label,
                                'block text-sm/6',
                                form.labelClass
                            )}
                        >
                            {form.label}
                        </label>
                    )}
                    <div className="">
                        <div className="flex items-center rounded-md">
                            <input
                                defaultValue={form.defaultValue}
                                {...register(form.name, {
                                    required: form.required,
                                })}
                                id={form.name}
                                type={form.type}
                                autoFocus={form.focus}
                                autoComplete={form.autoComplete || 'off'}
                                placeholder={form.placeholder}
                                className={classNames(formfield.input)}
                                aria-invalid={form.name ? 'true' : 'false'}
                            />
                        </div>
                        {errors[form.name] && (
                            <div className="text-sm text-red py-2 pl-2">
                                <span>This field is required.</span>
                            </div>
                        )}
                    </div>
                </div>
            )
    }
}

export default FormField
