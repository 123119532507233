'use client'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { BellIcon, MoonIcon } from '@heroicons/react/24/outline'
import { Link, Outlet } from 'react-router-dom'
import EnvioLogo from '../../components/EnvioLogo'
import Icon from '../../components/Icon'
import { useAuth } from '../../util/auth'
import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import DashboardNavigation from '../../components/DashboardNavigation'
import Alert from '../../components/Alert'

const changeTheme = () => {
    const html = document.getElementsByTagName('HTML')[0]
    html.classList.toggle('dark')

    if (html.classList.contains('dark')) {
        document.body.style.backgroundColor = '#1E293B'
    } else {
        document.body.style.backgroundColor = '#F3F3F3'
    }
}

export default function DashboardLayout() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [notification, setNotification] = useState({ show: false })
    const auth = useAuth()

    const userNavigation = [
        {
            icon: 'user',
            label: 'Profile',
            to: '#',
        },
        {
            icon: 'lock',
            label: 'Sign out',
            to: '#',
            onClick: (e) => {
                e.preventDefault()
                auth.signout()
            },
        },
    ]

    return (
        <div className="flex flex-col">
            <header className="shrink-0 border-b border-border ">
                <div className="mx-auto flex h-16 max-w-7xl items-center justify-between">
                    <div className="px-6 pl-14">
                        <Link
                            to="/"
                            className="justify-left "
                            target="_self"
                            rel="noreferrer"
                        >
                            <EnvioLogo />
                        </Link>
                    </div>
                    <div className="pr-4">
                        <div className="flex items-center gap-x-8">
                            {notification.show ? (
                                Alert(notification)
                            ) : (
                                <div className="flex items-center gap-x-8">
                                    <button
                                        type="button"
                                        className="-m-2.5 p-2.5 text-silver-500 hover:text-silver-100"
                                    >
                                        <span className="sr-only">
                                            View notifications
                                        </span>
                                        <BellIcon
                                            aria-hidden="true"
                                            className="size-6"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        className="-m-2.5 p-2.5 text-silver-500 hover:text-silver-100"
                                        onClick={() => {
                                            changeTheme()
                                        }}
                                    >
                                        <span className="sr-only">
                                            Switch Dark/Day Mode
                                        </span>
                                        <MoonIcon
                                            aria-hidden="true"
                                            className="size-6"
                                        />
                                    </button>
                                </div>
                            )}

                            {/* Separator */}
                            <div
                                aria-hidden="true"
                                className="h-6 w-px bg-silver-500 lg:hidden"
                            />
                            {/* Profile dropdown */}
                            <Menu as="div" className="relative">
                                <MenuButton className="-m-1.5 flex items-center p-1.5">
                                    <span className="sr-only">
                                        Open user menu
                                    </span>
                                    <img
                                        alt={auth.user?.displayName}
                                        src={auth.user?.picture}
                                        className="size-8 rounded-full bg-silver-500"
                                    />
                                    <span className="hidden lg:flex lg:items-center">
                                        <span
                                            aria-hidden="true"
                                            className="ml-4 text-sm/6 font-semibold text-silver-500 hover:text-silver-100"
                                        >
                                            {auth.user?.displayName}
                                        </span>
                                        <ChevronDownIcon
                                            aria-hidden="true"
                                            className="ml-2 size-5 text-silver-500 hover:text-silver-100"
                                        />
                                    </span>
                                </MenuButton>
                                <MenuItems
                                    transition
                                    className="absolute right-0 z-10 py-2 w-32 origin-top-right rounded-md bg-midnight-800 shadow-lg ring-1 ring-silver-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    {userNavigation.map((item) => (
                                        <MenuItem
                                            key={item.label}
                                            className="px-2 "
                                        >
                                            <Link
                                                to={item.to}
                                                onClick={item.onClick}
                                                className="block px-3 py-2 text-sm/6 text-silver-500 data-[focus]:bg-blue-500 data-[focus]:text-white data-[focus]:outline-none"
                                            >
                                                <Icon
                                                    name={item.icon}
                                                    size={12}
                                                    className="mr-2"
                                                />{' '}
                                                {item.label}
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </MenuItems>
                            </Menu>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mx-auto flex w-full max-w-7xl items-start px-4 sm:px-6 lg:px-2 ">
                <aside className="sticky top-8 hidden w-56 shrink-0 lg:block px-4 ">
                    <DashboardNavigation />
                </aside>
                <main className="flex-1 h-full max-h-full border-r border-l border-silver dark:border-border">
                    <Outlet
                        context={{
                            setSidebarOpen,
                            setNotification,
                        }}
                    />
                </main>
                {sidebarOpen && (
                    <aside className="sticky top-8 hidden w-96 shrink-0 xl:block pr-4 border-l   border-silver dark:border-border"></aside>
                )}
            </div>
        </div>
    )
}
