import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Outlet } from 'react-router-dom'

function DefaultLayout() {
    return (
        <>
            <Navbar bgColor="bg-midnight" />

            <Outlet />

            <Footer
                size="md"
                bgColor="bg-midnight"
                bgImage=""
                bgImageOpacity={1}
                textColor="text-silver-100 "
                sticky={true}
            />
        </>
    )
}

export default DefaultLayout
