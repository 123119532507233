import { format } from 'date-fns'
import { enGB, nl } from 'date-fns/locale'

export default function EventHeader({ eventDetails }) {
    return (
        <div className="border-b border-border pb-2 sm:flex sm:items-center sm:justify-between bg-gradient-to-r from-pink-500 to-blue-500">
            <div className="text-xl font-semibold text-5xl text-silver-100 p-4 pl-8 drop-shadow-md">
                {eventDetails.name}
                <div className="text-sm font-base text-5xl text-silver-100 drop-shadow-md">
                    {format(eventDetails.date, 'eeee d MMMM yyyy', {
                        locale: eventDetails.locale === 'nl' ? nl : enGB,
                    })}
                </div>
            </div>

            <div className="mt-3 flex sm:ml-4 sm:mt-0">
                {/* <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-silver-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-silver-50"
          >
            Share
          </button>
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Create
          </button> */}
            </div>
        </div>
    )
}
