const EnvioLogo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 900 175"
        height="25"
    >
        <g filter="url(#f0)">
            <path
                d="M199.1 12.3H41.3c-6 0-11.5 2.4-15.5 6.2-4.5 4.2-7.3 10.2-7.3 16.9V81h177.1v11.5H18.5v45.6c0 12.7 10.1 22.8 22.8 22.8h154.3v11.5H41.3C22.4 172.4 7 157 7 138.1V35.4C7 16.4 22.4 1 41.3 1h157.8M414.5 75.2v97.2H403V75.2c0-9.4-7.7-17-17-17H243.2v114.2h-11.5V46.6H386c15.6 0 28.5 13 28.5 28.6zM639.2 46.6l-74 116.2c-3.3 5.3-11.2 9.6-17.5 9.6H530c-6.3 0-14.2-4.3-17.6-9.6l-9.3-14.7-57.8-89.9L407.6.5H421l36.6 55.9 56.7 87.9 7.5 11.8c1.7 2.6 5.5 4.8 8.6 4.8H547c3.1 0 7.2-2.2 8.9-4.8l69.6-109.5zM663.2 46.6h11.5v125.8h-11.5z"
                className="a fill-white dark:fill-white"
            ></path>
            <path
                fillRule="evenodd"
                d="M893.6 75.2v68.6c0 15.9-13 28.6-28.6 28.6H739.2c-15.8 0-28.5-12.7-28.5-28.6V75.2c0-15.6 12.7-28.6 28.5-28.6H865c15.6 0 28.6 13 28.6 28.6zm-11.6 0c0-9.4-7.6-17-17-17H739.2c-9.3 0-17 7.6-17 17v68.6c0 9.4 7.7 17.1 17 17.1H865c9.4 0 17-7.7 17-17.1z"
                className="a fill-white dark:fill-white"
            ></path>
        </g>
    </svg>
)

export default EnvioLogo
