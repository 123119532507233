import React from 'react'
import Meta from './../components/Meta'
import FaqSection from './../components/FaqSection'

function FaqPage(props) {
    return (
        <>
            <Meta title="Faq" />
            <FaqSection
                title="Frequently Asked Questions"
                subtitle=""
                strapline="We answer"
                size="md"
                bgColor="bg-silver"
                bgImage=""
                bgImageOpacity={1}
                textColor=""
                showSupportButton={false}
                supportUrl=""
            />
        </>
    )
}

export default FaqPage
