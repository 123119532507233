import React from 'react'
import Meta from './../../components/Meta'
import SettingsSection from './../../components/SettingsSection'
import { useParams } from './../../util/router'

function DashboardSettingsPage(props) {
    const params = useParams()

    return (
        <>
            <Meta title="Settings" />
            <SettingsSection
                size="md"
                bgColor="bg-white"
                bgImage=""
                bgImageOpacity={1}
                section={params.section}
                key={params.section}
            />
        </>
    )
}

export default DashboardSettingsPage
