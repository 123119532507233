import React from 'react'
import Meta from './../components/Meta'
import HeroSection from './../components/HeroSection'
import FeaturesSection from './../components/FeaturesSection'

function IndexPage(props) {
    return (
        <>
            <Meta />
            <HeroSection
                title="Taking event support seriously"
                subtitle="Audiovisual support, on-demand, controlled remotely"
                strapline="Future ready and durable"
                size="md"
                bgColor="bg-blue dark:bg-midnight"
                bgImage="/assets/img/auditorium_v9_blurred.png"
                bgImageOpacity={1}
                textColor="text-white dark:text-silver"
            />

            <FeaturesSection
                title="The best tech under the hood"
                subtitle="We offer custom services to fit the needs of your daily operation."
                strapline="Created with experience and passion"
                size="md"
                bgColor="bg-silver"
                bgImage=""
                bgImageOpacity={1}
                textColor=""
            />

            {/* <NewsletterSection
        title="Subscribe to our newsletter"
        subtitle="Join us and receive the best curated news, freebies and resources directly to your inbox every week!"
        strapline=""
        size="lg"
        bgColor="bg-white dark:bg-midnight"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> */}
        </>
    )
}

export default IndexPage
