import React, { useState } from 'react'
import NewsFlash from './NewsFlash'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { CalendarIcon } from './CalendarIcon'
import { dropdown } from './class-groups'
import { Link, useNavigate } from 'react-router-dom'
import DeleteModal from './DeleteModal'
import SubMenuNavigation from './SubMenuNavigation'
import classNames from './class-names'
import Button from './Button'
import { PlusIcon } from '@heroicons/react/24/outline'
import { createEvent, updateEvent } from '../util/db_events'
import PageLoader from './PageLoader'
import eventWizzard from '../datasets/event-wizzard.json'
import DataModal from './DataModal'
import { useForm } from 'react-hook-form'
import { convertToFirebaseTimestamp, firebaseTimestamp } from '../util/util'
import Icon from './Icon'
import { Timestamp } from 'firebase/firestore'
import { useEvents } from '../hooks/useEvents'
import NoItemsToShow from './NoItemsToShow'
import { useAuth } from '../util/auth'
import { useOrganisation } from '../hooks/useOrganisation'

const statuses = {
    scheduled: 'text-white bg-blue ring-blue',
    live: 'text-white bg-red ring-red',
    postphoned: 'text-black bg-gold ring-gold',
    archived: 'text-black bg-orange-300 ring-orange-300',
}

const secondaryNavigation = {
    menu: [
        { name: 'Upcoming Events', to: '/events' },
        { name: 'Event Archive', to: '/events/archive' },
    ],
}

function DashboardSection({ archive }) {
    const auth = useAuth()
    const { selectedOid, selectedOrganisation } = useOrganisation()

    const { eventsForOid, loading } = useEvents()
    const navigate = useNavigate()

    const reactHookForm = useForm({
        mode: 'all',
    })

    const archiveEvent = async (eid) => {
        try {
            updateEvent(eid, {
                archived: true,
                archivedAt: firebaseTimestamp(),
                status: 'archived',
            })
        } catch (e) {
            console.log("Event counldn't be archived.", e)
        }
    }

    const onSubmit = async (formData) => {
        // async request which may result error
        try {
            formData.owner = auth.user.id
            formData.oid = selectedOid
            formData.status = 'scheduled'
            formData.createdAt = firebaseTimestamp()
            formData.deleted = 0
            formData.locale = selectedOrganisation?.locale
            formData.eventStart = convertToFirebaseTimestamp(
                `${formData.date} ${formData.eventStart}`
            )
            formData.eventEnd = convertToFirebaseTimestamp(
                `${formData.date} ${formData.eventEnd}`
            )
            formData.showStart = convertToFirebaseTimestamp(
                `${formData.date} ${formData.showStart}`
            )
            formData.showEnd = convertToFirebaseTimestamp(
                `${formData.date} ${formData.showEnd}`
            )
            createEvent(formData)
            setEventModalState({ show: false })
        } catch (e) {
            console.log('Error creating event', e)
        }
    }

    const [deleteState, setDeleteState] = useState({
        show: false,
        name: null,
        id: null,
    })
    const [eventModalState, setEventModalState] = useState({
        show: false,
        id: null,
    })

    return (
        <>
            <header className="sticky">
                <NewsFlash
                    headline="Yes, you're finally at the Envio Platform."
                    tagline="Enjoy the new features and ease of use."
                    label="Dismiss"
                />
                <div className="flex flex-column space-between border-b border-silver-500 dark:border-white/10">
                    <div className="flex-1">
                        {/* Secondary navigation */}
                        <SubMenuNavigation
                            menuObject={secondaryNavigation.menu}
                            border={false}
                            subPath={''}
                        />
                    </div>
                    <div className="text-right mx-4 my-2">
                        <Button
                            onClick={() => setEventModalState({ show: true })}
                            type="button"
                            startIcon={
                                <PlusIcon className="inline-block w-5" />
                            }
                            className=" w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        >
                            New Event
                        </Button>
                    </div>
                </div>
            </header>

            <div className="m-0">
                <ul className="divide-y divide-midnight/10 dark:divide-white/10 py-0">
                    {loading ? (
                        <PageLoader />
                    ) : (
                        (() => {
                            // Filter de events eerst
                            const filteredEvents = eventsForOid.filter(
                                (evnt) => {
                                    if (archive) {
                                        return evnt.archived
                                            ? true
                                            : evnt.eventEnd.seconds <
                                                  Timestamp.now().seconds
                                    } else {
                                        return evnt.archived
                                            ? false
                                            : evnt.eventEnd.seconds >=
                                                  Timestamp.now().seconds
                                    }
                                }
                            )

                            // Controleer of de gefilterde array leeg is
                            if (filteredEvents.length === 0) {
                                return <NoItemsToShow unit="events" />
                            }

                            // Toon de gefilterde events
                            return filteredEvents.map((event) => (
                                <li
                                    key={event.id}
                                    className="flex items-center justify-between gap-x-6 py-5 px-6 text-black dark:text-white dark:hover:bg-midnight-600 hover:bg-silver-200 hover:cursor-pointer"
                                >
                                    <div
                                        className="flex flex-none items-start"
                                        onClick={() =>
                                            navigate(`/event/${event.id}`)
                                        }
                                    >
                                        <CalendarIcon date={event.eventStart} />
                                    </div>

                                    <div
                                        className="flex grow items-start gap-x-3"
                                        onClick={() =>
                                            navigate(`/event/${event.id}`)
                                        }
                                    >
                                        <div className="">
                                            <p className="text-lg font-bold">
                                                {event.name}
                                            </p>
                                            <p className="text-sm font-normal text-silver-200">
                                                {event.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs/5 text-silver-500">
                                        <p className="whitespace-nowrap"></p>
                                    </div>

                                    <div className="flex flex-none items-center gap-x-4">
                                        <span
                                            className={classNames(
                                                statuses[event.status],
                                                'mr-2 whitespace-nowrap rounded-md px-1.5 py-1 text-sm font-medium ring-1 ring-inset uppercase'
                                            )}
                                        >
                                            {event.status}
                                        </span>
                                        <Link
                                            to={`/event/${event.id}`}
                                            className={`className="hidden rounded-md px-2.5 py-1.5 text-sm font-semibold text-silver-500 shadow-sm ring-1 ring-inset ring-midnight dark:ring-silver-100 sm:block"`}
                                        >
                                            View Event{' '}
                                            <span className="sr-only">
                                                , {event.name}
                                            </span>
                                        </Link>
                                        <Menu
                                            as="div"
                                            className={`relative flex-none`}
                                        >
                                            <MenuButton className="-m-2.5 block p-2.5 hover:text-silver-100">
                                                <span className="sr-only">
                                                    Open options
                                                </span>
                                                <EllipsisVerticalIcon
                                                    aria-hidden="true"
                                                    className="size-5"
                                                />
                                            </MenuButton>
                                            <MenuItems
                                                transition
                                                className={`${dropdown.menu}`}
                                            >
                                                <MenuItem>
                                                    <Link
                                                        to={`/event/${event.id}`}
                                                        className={
                                                            dropdown.item
                                                        }
                                                    >
                                                        <Icon
                                                            name="edit"
                                                            size={12}
                                                            className="mr-2"
                                                        />
                                                        Edit
                                                        <span className="sr-only">
                                                            , {event.name}
                                                        </span>
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem>
                                                    <button
                                                        onClick={() =>
                                                            archiveEvent(
                                                                event.id
                                                            )
                                                        }
                                                        className={`${dropdown.item} text-orange-300 dark:text-orange-300 hover:dark:text-orange-300 w-full text-left`}
                                                    >
                                                        <Icon
                                                            name="archive"
                                                            size={12}
                                                            className="mr-2"
                                                        />
                                                        Archive
                                                        <span className="sr-only">
                                                            , {event.name}
                                                        </span>
                                                    </button>
                                                </MenuItem>
                                                <MenuItem>
                                                    <button
                                                        onClick={() =>
                                                            setDeleteState({
                                                                id: event.id,
                                                                name: event.name,
                                                                show: true,
                                                            })
                                                        }
                                                        className={`${dropdown.item} text-red dark:text-red hover:dark:text-red w-full text-left`}
                                                    >
                                                        <Icon
                                                            name="trash"
                                                            size={12}
                                                            className="mr-2"
                                                        />
                                                        Delete
                                                        <span className="sr-only">
                                                            , {event.name}
                                                        </span>
                                                    </button>
                                                </MenuItem>
                                            </MenuItems>
                                        </Menu>
                                    </div>
                                </li>
                            ))
                        })()
                    )}
                </ul>
            </div>

            <DataModal
                open={eventModalState.show}
                onClose={() => {
                    setEventModalState({ show: false })
                }}
                onSubmit={onSubmit}
                dataset={eventWizzard}
                reactHookForm={reactHookForm}
            />

            <DeleteModal
                deleteState={deleteState}
                setDeleteState={setDeleteState}
                confirmModal={() => {
                    console.log('Deleting', deleteState.name)
                    updateEvent(deleteState.id, {
                        deleted: 1,
                        deletedAt: firebaseTimestamp(),
                    })
                }}
            />
        </>
    )
}

export default DashboardSection
