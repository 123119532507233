import React from 'react'
import './../styles/global.css'
import './../util/analytics'
// import Chat from "./../components/Chat";
import { AuthProvider } from './../util/auth'
import { QueryClientProvider } from './../util/db'
import { router } from './router'
import { RouterProvider } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

function App(props) {
    library.add(far, fas, faFontAwesome)
    return (
        <QueryClientProvider>
            <AuthProvider>
                {/* <Chat /> */}
                <RouterProvider router={router} />
            </AuthProvider>
        </QueryClientProvider>
    )
}

export default App
